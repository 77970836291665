define("discourse/plugins/discourse-topic-alarm/discourse/components/modal/topic-alarm-editor", ["exports", "@ember/component", "discourse/lib/ajax", "discourse/lib/ajax-error", "@ember/object", "@glimmer/tracking", "@discourse/itsatrap", "discourse/lib/bookmark", "discourse/lib/time-shortcut", "discourse-i18n", "@ember/service", "@ember/template-factory"], function (_exports, _component, _ajax, _ajaxError, _object, _tracking, _itsatrap, _bookmark, _timeShortcut, _discourseI18n, _service, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal @title={{this.modalTitle}} @closeModal={{@closeModal}} class="topic-alarm-editor">
    <:body>
      <Input
        type="text"
        @value={{this.model.topic.topic_alarm_description}}
        placeholder={{i18n "topic_alarm.description_placeholder"}}
      />
      {{#if this.hasTopicAlarm}}
        <div class="alert alert-info existing-reminder-at-alert">
          {{d-icon "far-clock"}}
          <span>{{i18n "topic_alarm.existing_alarm" at_date_time=this.existingReminderAtFormatted}}</span>
        </div>
      {{/if}}
      <TimeShortcutPicker
        @timeShortcuts={{this.timeOptions}}
        @prefilledDatetime={{this.prefilledDatetime}}
        @onTimeSelected={{this.onTimeSelected}}
        @hiddenOptions={{this.hiddenTimeShortcutOptions}}
        @customLabels={{this.customTimeShortcutLabels}}
        @_itsatrap={{this._itsatrap}}
      />
    </:body>
    <:footer>
      <DButton
        @action={{action "setTopicAlarm"}}
        class="btn-primary"
        @label="topic_alarm.set_topic_alarm_button.label"
        @disabled={{this.hasNoTopicAlarm}}
      />
      {{#if this.hasExistingTopicAlarm}}
        <DButton
          @action={{action "deleteTopicAlarm"}}
          class="btn-primary"
          @label="topic_alarm.delete_topic_alarm_button.label"
        />
      {{/if}}
    </:footer>
  </DModal>
  */
  {
    "id": "nX111Dm0",
    "block": "[[[8,[39,0],[[24,0,\"topic-alarm-editor\"]],[[\"@title\",\"@closeModal\"],[[30,0,[\"modalTitle\"]],[30,1]]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[8,[39,2],[[16,\"placeholder\",[28,[37,3],[\"topic_alarm.description_placeholder\"],null]],[24,4,\"text\"]],[[\"@value\"],[[30,0,[\"model\",\"topic\",\"topic_alarm_description\"]]]],null],[1,\"\\n\"],[41,[30,0,[\"hasTopicAlarm\"]],[[[1,\"      \"],[10,0],[14,0,\"alert alert-info existing-reminder-at-alert\"],[12],[1,\"\\n        \"],[1,[28,[35,6],[\"far-clock\"],null]],[1,\"\\n        \"],[10,1],[12],[1,[28,[35,3],[\"topic_alarm.existing_alarm\"],[[\"at_date_time\"],[[30,0,[\"existingReminderAtFormatted\"]]]]]],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"    \"],[8,[39,8],null,[[\"@timeShortcuts\",\"@prefilledDatetime\",\"@onTimeSelected\",\"@hiddenOptions\",\"@customLabels\",\"@_itsatrap\"],[[30,0,[\"timeOptions\"]],[30,0,[\"prefilledDatetime\"]],[30,0,[\"onTimeSelected\"]],[30,0,[\"hiddenTimeShortcutOptions\"]],[30,0,[\"customTimeShortcutLabels\"]],[30,0,[\"_itsatrap\"]]]],null],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,10],[[24,0,\"btn-primary\"]],[[\"@action\",\"@label\",\"@disabled\"],[[28,[37,11],[[30,0],\"setTopicAlarm\"],null],\"topic_alarm.set_topic_alarm_button.label\",[30,0,[\"hasNoTopicAlarm\"]]]],null],[1,\"\\n\"],[41,[30,0,[\"hasExistingTopicAlarm\"]],[[[1,\"      \"],[8,[39,10],[[24,0,\"btn-primary\"]],[[\"@action\",\"@label\"],[[28,[37,11],[[30,0],\"deleteTopicAlarm\"],null],\"topic_alarm.delete_topic_alarm_button.label\"]],null],[1,\"\\n\"]],[]],null],[1,\"  \"]],[]]]]]],[\"@closeModal\"],false,[\"d-modal\",\":body\",\"input\",\"i18n\",\"if\",\"div\",\"d-icon\",\"span\",\"time-shortcut-picker\",\":footer\",\"d-button\",\"action\"]]",
    "moduleName": "discourse/plugins/discourse-topic-alarm/discourse/components/modal/topic-alarm-editor.hbs",
    "isStrictMode": false
  });
  class TopicAlarmEditor extends _component.default {
    static #_ = (() => dt7948.g(this.prototype, "currentUser", [_service.inject]))();
    #currentUser = (() => (dt7948.i(this, "currentUser"), void 0))();
    static #_2 = (() => dt7948.g(this.prototype, "prefilledDatetime", [_tracking.tracked], function () {
      return null;
    }))();
    #prefilledDatetime = (() => (dt7948.i(this, "prefilledDatetime"), void 0))();
    static #_3 = (() => dt7948.g(this.prototype, "userTimezone", [_tracking.tracked], function () {
      return this.currentUser.user_option.timezone;
    }))();
    #userTimezone = (() => (dt7948.i(this, "userTimezone"), void 0))();
    _itsatrap = (() => new _itsatrap.default())();
    init() {
      super.init(...arguments);
    }

    /*** date/time picker ***/

    get timeOptions() {
      const shortcuts = (0, _timeShortcut.timeShortcuts)(this.currentUser.user_option.timezone);
      return [shortcuts.tomorrow(), shortcuts.monday(), shortcuts.nextMonth()];
    }
    onTimeSelected(type, time) {
      var ts = time.unix();
      this.model.topic.set("topic_alarm_time", ts);
      this.model.topic.set("topic_alarm_user_time", ts);
    }
    static #_4 = (() => dt7948.n(this.prototype, "onTimeSelected", [_object.action]))();
    get hiddenTimeShortcutOptions() {
      return [_timeShortcut.TIME_SHORTCUT_TYPES.NONE];
    }
    get customTimeShortcutLabels() {
      const labels = {};
      return labels;
    }
    get existingReminderAtFormatted() {
      return (0, _bookmark.formattedReminderTime)(this.model.topic.get("topic_alarm_time") * 1000, this.userTimezone);
    }

    /*** other functionality */

    get hasTopicAlarm() {
      return this.model.topic.get("topic_alarm_time") > 0;
    }
    get hasExistingTopicAlarm() {
      return this.model.existing_alarm;
    }
    get hasNoTopicAlarm() {
      return !this.hasTopicAlarm;
    }
    get modalTitle() {
      const action = this.hasExistingTopicAlarm ? "edit" : "create";
      return _discourseI18n.default.t(`topic_alarm.alarm_editor.${action}`);
    }
    setTopicAlarm() {
      (0, _ajax.ajax)("/topic-alarm/set", {
        type: "POST",
        data: {
          topic_id: this.model.topic.id,
          topic_alarm_time: this.model.topic.get("topic_alarm_time"),
          topic_alarm_description: this.model.topic.get("topic_alarm_description")
        }
      }).then(() => {}).catch(_ajaxError.popupAjaxError).finally(() => {
        this.closeModal();
      });
    }
    static #_5 = (() => dt7948.n(this.prototype, "setTopicAlarm", [_object.action]))();
    deleteTopicAlarm() {
      (0, _ajax.ajax)("/topic-alarm/destroy", {
        type: "DELETE",
        data: {
          topic_id: this.model.topic.id
        }
      }).then(() => {
        this.model.topic.set("topic_alarm_time", null);
        this.model.topic.set("topic_alarm_user_time", null);
        this.model.topic.set("topic_alarm_description", null);
      }).catch(_ajaxError.popupAjaxError).finally(() => {
        this.closeModal();
      });
    }
    static #_6 = (() => dt7948.n(this.prototype, "deleteTopicAlarm", [_object.action]))();
  }
  _exports.default = TopicAlarmEditor;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, TopicAlarmEditor);
});